/* eslint-disable */
import { userConstants } from '../constants';
import { userService } from '../services/user.service';
import { alertActions, authModalActions, userLoginNameActions } from './';
import { message } from 'antd';
import { personalAreaPath, p2p } from '../constants';
import PostService from '../services/post-service';
import { history } from '../helpers';

export const userActions = {
    login,
    logout,
    logoutSessionOnTheBackend,
    register,
    twoFactorAuthorization,
    success,
};

function login(username, password, history, t) {
    return dispatch => {
        dispatch(request({ username }));
        dispatch(userLoginNameActions.userLoginName(username));

        userService.login(username, password, history).then(
            user => {
                dispatch(success(user));
                dispatch(alertActions.success('Authorization successful'));
                dispatch(authModalActions.closeModal());
                history.push(`${personalAreaPath}${p2p}`);
                message.success(t('auth.authSuccessful'), 5);
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            },
        );
    };
}

function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
}
function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
}
function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
}

function logout() {
    history.push('/')
    localStorage.removeItem('user');
    return { type: userConstants.LOGOUT };
}

function logoutSessionOnTheBackend() {
    const postService = new PostService();

    postService
    .logout()
    .then(() => {
        console.log('Success logout');
    })
    .catch(err => console.log(err, 'error'));

    history.push('/');
    localStorage.removeItem('user');
    return { type: userConstants.LOGOUT };
}

function twoFactorAuthorization(twoAuthCode, password, username, history, t) {
    return dispatch => {
        dispatch(request({ twoAuthCode }));

        userService.twoFactorAuthorization(twoAuthCode, password, username, history).then(
            user => {
                dispatch(success(user));
                dispatch(alertActions.success('Authorization successful'));
                dispatch(authModalActions.closeModal());
                history.push(`${personalAreaPath}${p2p}`);
                message.success(t('auth.authSuccessful'), 5);
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                dispatch(userActions.logout());
            },
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user).then(
            user => {
                dispatch(success());
                dispatch(alertActions.success('Registration successful'));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            },
        );
    };

    function request(user) {
        return {
            type: userConstants.REGISTER_REQUEST,
            user,
        };
    }

    function success(user) {
        return {
            type: userConstants.REGISTER_SUCCESS,
            user,
        };
    }

    function failure(error) {
        return {
            type: userConstants.REGISTER_FAILURE,
            error,
        };
    }
}
