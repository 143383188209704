import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, Spin } from 'antd';

import Field from '../../small-components/field';
import { compose } from '../../../utils';
import Button from '../../small-components/button';
import { authModalActions, userActions } from '../../../actions';
import { termOfServicePath, privacyPolicyPath } from '../../../constants';

import close from '../../assets/images/close.svg';
import style from './registration.module.scss';

class Registration extends Component {
    state = {
        user: {
            username: '',
            email: '',
            password: '',
            leaderUsername: '',
            checkbox: false,
            privacyPolicy: false,
        },
        errors: {
            usernameError: '',
            emailError: '',
            passwordError: '',
            leaderUsernameError: '',
            checkboxError: '',
            privacyPolicyError: '',
        },
        termOfUseCheckbox: false,
        privacyPolicyCheckbox: false,
    };

    componentDidMount() {
        const { location } = this.props;
        const { user } = this.state;

        if (location.search) {
            this.setState({
                user: {
                    ...user,
                    leaderUsername: location.search.split('=')[1],
                },
            });
        }
    }

    InputOnchange = event => {
        const { name, value } = event.target;
        const { user } = this.state;

        this.setState({
            user: {
                ...user,
                [name]: value,
            },
        });
    };

    termOfUse = event => {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                checkbox: event.target.checked,
            },
        });
    };

    privacyPolicy = event => {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                privacyPolicy: event.target.checked,
            },
        });
    };

    termOfUseCheckbox = () => {
        const { termOfUseCheckbox } = this.state;
        this.setState({
            termOfUseCheckbox: !termOfUseCheckbox,
        });
    };

    privacyPolicyCheckbox = () => {
        const { privacyPolicyCheckbox } = this.state;
        this.setState({
            privacyPolicyCheckbox: !privacyPolicyCheckbox,
        });
    };

    closeModal = () => {
        const { dispatch } = this.props;
        dispatch(authModalActions.closeModal());
    };

    switchLogin = () => {
        const { dispatch } = this.props;
        dispatch(authModalActions.openLogin());
    };

    RegistrationSubmit = event => {
        event.preventDefault();
        const { t, dispatch } = this.props;
        const {
            user: {
                username, email, password, checkbox, privacyPolicy,
            },
        } = this.state;
        const { user } = this.state;
        const errors = {};

        if (username.length < 4 || username.length > 20) {
            errors.usernameError = t('error.messageMaxAmount', { min: 4, max: 20 });
        }

        if (!/^[~a-z]/.test(username.toLocaleLowerCase())) {
            errors.usernameError = t('error.name_should_be_start_and_end_with_letter');
        }

        if (!/[a-z0-9]$/.test(username.toLocaleLowerCase())) {
            errors.usernameError = t('error.name_should_be_start_and_end_with_letter');
        }

        if (!/^[~a-z0-9-._]*$/.test(username.toLocaleLowerCase())) {
            errors.usernameError = t(
                'error.name_should_be_have_only_letters_digits_or_dashed',
            );
        }

        if (username.length < 1) {
            errors.usernameError = t('error.field_cannot_be_empty');
        }

        if (email.length < 4) {
            errors.emailError = t('error.email');
        }

        if (password.length < 6) {
            errors.passwordError = t('error.input', { count: 6 });
        }

        if (password.length < 6) {
            errors.passwordError = t('error.input', { count: 6 });
        }

        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(password)) {
            errors.passwordError = t('error.password');
        }

        if (!checkbox) {
            errors.checkbox = true;
        }

        if (!privacyPolicy) {
            errors.privacyPolicy = true;
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
            });
        } else {
            this.setState({
                errors: {},
            });

            if (email && password && username) {
                dispatch(userActions.register(user, dispatch));
            }
        }
    };

    render() {
        const {
            t, registering,
        } = this.props;
        const {
            errors: {
                usernameError,
                emailError,
                passwordError,
                leaderUsernameError,
                checkbox,
                privacyPolicy,
            },
            user: {
                username, email, password, leaderUsername,
            },
        } = this.state;

        const registrModalStyle = {
            opacity: '0.5',
            pointerEvents: 'none',
            userSelect: 'none',
            transition: 'all 0.25s',
        };

        const loadingStyle = registering ? registrModalStyle : null;

        return (
            <Fragment>
                {registering ? <Spin size="large" /> : null}
                <div className={style.registration} style={loadingStyle}>
                    <h2 className={style.registration__title}>
                        {t('registration.title')}
                    </h2>
                    <p className={style.registration__subTitle}>
                        {t('registration.subtitle')}
                    </p>
                    <div onClick={this.closeModal}>
                        <img
                            src={close}
                            className={style.registration__close}
                            alt="logo"
                        />
                    </div>
                    <form onSubmit={this.RegistrationSubmit}>
                        <div className={style.registration__inputWrapper}>
                            <Field
                                id="usernameRegistration"
                                type="username"
                                placeholder={t('general.username')}
                                name="username"
                                value={username}
                                onChange={this.InputOnchange}
                                inputStyle={style.registration__input}
                                labelText={t('general.username')}
                                labelStyle={style.registration__label}
                            />
                            {usernameError ? (
                                <div className={style.registration__inputWrapper_invalid}>
                                    {usernameError}
                                </div>
                            ) : null}
                        </div>
                        <div className={style.registration__inputWrapper}>
                            <Field
                                id="emailregistration"
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={email}
                                onChange={this.InputOnchange}
                                error={emailError}
                                inputStyle={style.registration__input}
                                labelText="Email"
                                labelStyle={style.registration__label}
                            />
                        </div>
                        <div className={style.registration__inputWrapper}>
                            <Field
                                id="password"
                                type="password"
                                placeholder={t('form.password')}
                                name="password"
                                value={password}
                                onChange={this.InputOnchange}
                                inputStyle={style.registration__input}
                                labelText={t('form.password')}
                                labelStyle={style.registration__label}
                            />
                            {passwordError ? (
                                <div className={style.registration__inputWrapper_invalid}>
                                    {passwordError}
                                </div>
                            ) : null}
                        </div>
                        <div className={style.registration__inputWrapper}>
                            <Field
                                id="leaderUsername"
                                type="text"
                                placeholder={t('referralSystem.enterEmailOrReferralLink')}
                                name="leaderUsername"
                                value={leaderUsername}
                                onChange={this.InputOnchange}
                                error={leaderUsernameError}
                                inputStyle={style.registration__input}
                                labelText={t('referralSystem.emailOrReferralLink')}
                                labelStyle={style.registration__label}
                            />
                        </div>
                        <div className={style.registration__checkBoxWrapper}>
                            <span className={style.registration__checkbox}>
                                <div className={style.registration__checkbox_wrapper}>
                                    <Checkbox onChange={this.termOfUse} />
                                    <span
                                        className={style.registration__checkbox_title}
                                        onClick={() => this.termOfUseCheckbox()}
                                    >
                                        {t('termOfUse.iAgree')}
                                        <Link
                                            target="_blank"
                                            to={termOfServicePath}
                                            className={style.registration__termOfUseModal}
                                        >
                                            {t('termOfUse.TermsOfService')}
                                        </Link>
                                    </span>
                                </div>
                                {checkbox ? (
                                    <div
                                        className={
                                            style.registration__inputWrapper_invalid
                                        }
                                    >
                                        {t('termOfUse.needToAgree')}
                                    </div>
                                ) : null}
                            </span>
                            <span className={style.registration__checkbox}>
                                <div className={style.registration__checkbox_wrapper}>
                                    <Checkbox onChange={this.privacyPolicy} />
                                    <span
                                        className={style.registration__checkbox_title}
                                        onClick={() => this.privacyPolicyCheckbox()}
                                    >
                                        {t('termOfUse.iAgree')}
                                        <Link
                                            target="_blank"
                                            to={privacyPolicyPath}
                                            className={style.registration__termOfUseModal}
                                        >
                                            {t('privacyPolicy.privacyPolicy')}
                                        </Link>
                                    </span>
                                </div>
                                {privacyPolicy ? (
                                    <div
                                        className={
                                            style.registration__inputWrapper_invalid
                                        }
                                    >
                                        {t('termOfUse.needToAgree')}
                                    </div>
                                ) : null}
                            </span>
                        </div>
                        <div className={style.registration__buttonWrapper}>
                            <Button
                                id="registration-button"
                                type="submit"
                                disabled={registering}
                                className={style.registration__button}
                            >
                                {t('form.registration')}
                            </Button>
                            <Button
                                id="login-button"
                                type="button"
                                disabled={registering}
                                className={style.registration__switchLoginButton}
                                onClick={this.switchLogin}
                            >
                                {t('form.login')}
                            </Button>
                        </div>
                        <p className={style.registration__checkEmail}>
                            {t('registration.checkEmail')}
                        </p>
                    </form>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        authModal: { visibleLogin },
        registration: { registering },
    } = state;

    return {
        visibleLogin,
        registering,
    };
};

Registration.defaultProps = {
    t: () => {},
    location: {},
    dispatch: () => {},
    registering: false,
};

Registration.propTypes = {
    t: PropTypes.func,
    location: PropTypes.object,
    dispatch: PropTypes.func,
    registering: PropTypes.bool,
};

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter,
)(Registration);
