import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    personalAreaPath,
    p2p,
    chat,
    history,
    dashboardPath,
    advertisement,
    wallet,
    newOrder,
    room,
    editPath,
    profilePath,
    settingsPath,
    referralSystemPath,
    edcRentPath,
} from '../../../../constants';

import { compose } from '../../../../utils';
import Aside from './aside';
import P2PContainer from './p2p-page';
import ChatContainer from './chat-page';
import AllChats from './chat-page/all-chats';
import DashboardContainer from './dashboard-page';
import AdvertisementContainer from './advertisement-page';
import HistoryContainer from './history-page';
import WalletContainer from './wallet-page';
import NewOrderContainer from './new-order-page';
import EditContainer from './edit-page';
import ProfileContainer from './profile-page';
import SettingsContainer from './settings-page';
import ReferralSystem from './referral-system';

import style from './personal-area.module.scss';
import './personal-area.scss';
import EdcRent from './edc-rent/edc-rent';

const PersonalAreaView = ({ location }) => {
    const pathName = location.pathname;
    const matchPathName = pathName.split('/')[3];
    if (matchPathName === 'room') {
        document.body.classList.add('chatRoom');
    } else {
        document.body.classList.remove('chatRoom');
    }

    return (
        <div className={style.personalAreaWrapper}>
            <Aside />
            <Switch>
                <Route path={`${personalAreaPath}${p2p}`} component={P2PContainer} />
                <Route path={`${personalAreaPath}${wallet}`} component={WalletContainer} />
                <Route path={`${personalAreaPath}${history}`} component={HistoryContainer} />
                <Route path={`${personalAreaPath}${chat}`} component={AllChats} exact />
                <Route
                    path={`${personalAreaPath}${referralSystemPath}`}
                    component={ReferralSystem}
                />
                <Route
                    path={`${personalAreaPath}${profilePath}/:id?`}
                    component={ProfileContainer}
                />
                <Route
                    path={`${personalAreaPath}${chat}${room}/:id?`}
                    component={ChatContainer}
                    exact
                />
                <Route
                    path={`${personalAreaPath}${dashboardPath}`}
                    component={DashboardContainer}
                    exact
                />
                <Route
                    path={`${personalAreaPath}${dashboardPath}${advertisement}/:id?`}
                    component={AdvertisementContainer}
                    exact
                />
                <Route
                    path={`${personalAreaPath}${dashboardPath}${editPath}/:id?`}
                    component={EditContainer}
                />
                <Route
                    path={`${personalAreaPath}${newOrder}`}
                    component={NewOrderContainer}
                />
                {/*<Route*/}
                {/*    path={`${personalAreaPath}${edcRentPath}`}*/}
                {/*    component={EdcRent}*/}
                {/*/>*/}
                <Route
                    path={`${personalAreaPath}${settingsPath}`}
                    component={SettingsContainer}
                />
            </Switch>
        </div>
    );
};

PersonalAreaView.defaultProps = {
    location: {},
};

PersonalAreaView.propTypes = {
    location: PropTypes.object,
};

export default compose(withRouter)(PersonalAreaView);
