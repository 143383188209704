import { message } from 'antd';
import store, { authHeader } from '../helpers';
import {
    REQUIRED_2FA_CODE,
    UNABLE_TO_DELETE_USER_HAS_ARBITRAGE_ORDERS,
    UNABLE_TO_DELETE_USER_HAS_PENDING_ORDERS,
} from '../constants';
import PostService from './post-service';
import { userActions } from '../actions';
import i18n from '../i18n';


export default class GetService {
    postService = new PostService();

    getResource = async url => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
            headers: authHeader(),
        });

        const result = await res.json();

        if (res.status === 401) {
            this.removeUserFromStorage();
        }

        if (result.error === UNABLE_TO_DELETE_USER_HAS_ARBITRAGE_ORDERS) {
            message.error(i18n.t('error.unableDeleteUserHasArbitrageOrders'), 5);
        }
        if (result.error === UNABLE_TO_DELETE_USER_HAS_PENDING_ORDERS) {
            message.error(i18n.t('error.unableDeleteUserHasPendingOrders'), 5);
        }

        if (
            !res.ok
            && result.error !== REQUIRED_2FA_CODE && res.status !== 401
        ) {
            throw new Error(`Could not fetch ${url}, received ${res.status}`);
        }
        return result;
    };

    removeUserFromStorage = () => {
        store.dispatch(userActions.logout());
    };

    getUserInfo = async () => {
        const res = await this.getResource('/api/user');
        return res;
    };

    getCurrentUser = async user => {
        const res = await this.getResource(`/api/user/${user}/profile-setting`);
        return res;
    };

    getAllOrders = async data => {
        const filterParams = data ? `?${data}` : '';
        const res = await this.getResource(`/public/orders${filterParams}`);
        return res;
    };

    getUserOrders = async data => {
        const filterParams = data ? `?${data}` : '';
        const res = await this.getResource(`/api/user-orders${filterParams}`);
        return res;
    };

    getOneOrder = async filterParams => {
        const params = filterParams || '';
        const res = await this.getResource(`/api/order/${params}`);
        return res;
    };

    getAllCoins = async () => {
        const res = await this.getResource('/public/coins');
        return res;
    };

    getUserCoins = async () => {
        const res = await this.getResource('/api/user/coins');
        return res;
    };

    getUserTransactions = async data => {
        const filterParams = data ? `?${data}` : '';
        const res = await this.getResource(`/api/user-transactions/${filterParams}`);
        return res;
    };

    getAllChats = async () => {
        const res = await this.getResource('/api/chats');
        return res;
    };

    getChatInfo = async (id, locale) => {
        const res = await this.getResource(`/api/chat/${id}?locale=${locale}`);
        return res;
    };

    getChatMessages = async data => {
        const res = await this.getResource(`/api/chat/${data}/messages`);
        return res;
    };

    getChatStatus = async data => {
        const res = await this.getResource(`/api/chat/status/${data}`);
        return res;
    };

    getDeposit = async () => {
        const res = await this.getResource('/api/deposit');
        return res;
    };

    getUserProfile = async id => {
        const res = await this.getResource(`/api/user/${id}/profile`);
        return res;
    };

    getUserReviews = async (id, page) => {
        const currentNumber = page ? `?currentPageNumber=${page}` : '';
        const res = await this.getResource(`/api/user-reviews/${id}${currentNumber}`);
        return res;
    };

    removeUser = async () => {
        const res = await this.getResource('/api/user/remove');
        return res;
    };

    toggle2fa = async () => {
        const res = await this.getResource('/api/user/settings/2fa/toggle');
        return res;
    };

    getNotifications = async () => {
        const res = await this.getResource('/api/user/notifications');
        return res;
    };

    getFaq = async (data, locale) => {
        const res = await this.getResource(`/public/faq/${locale}?page=${data}`);
        return res;
    };

    getNotificationSattings = async () => {
        const res = await this.getResource('/api/user/notification/settings');
        return res;
    };

    getUserHistory = async () => {
        const res = await this.getResource('/api/user/history');
        return res;
    };

    getActiveUserHistory = async () => {
        const res = await this.getResource('/api/user/active-token');
        return res;
    };

    getUserLocation = async () => {
        const res = await this.getResource('/api/user/geo-locations');
        return res;
    };

    getBlackList = async () => {
        const res = await this.getResource('/api/user/black-list');
        return res;
    };

    getUserCard = async () => {
        const res = await this.getResource('/api/user-bank-cards');
        return res;
    };

    getMarketPrice = async (coin, toCoin) => {
        const res = await this.getResource(`/api/coin-rate/${coin}/${toCoin}`);
        return res;
    };

    withdrawRequests = async () => {
        const res = await this.getResource('/api/withdraw-requests');
        return res;
    };

    isValidConfirmationToken = async confirmationToken => {
        const res = await this.getResource(
            `/public/confirmation-token/check/${confirmationToken}`,
        );
        return res;
    };

    coinsPublic = async () => {
        const res = await this.getResource('/public/coins');
        return res;
    };

    getPaymentMethods = async () => {
        const res = await this.getResource('/public/payment-methods');
        return res;
    };

    getMyReferralsPagination = async pagination => {
        const paginationItem = pagination ? `?currentPageNumber=${pagination}` : '';
        const res = await this.getResource(`/api/my-referrals${paginationItem}`);
        return res;
    };

    getMyReferrals = async data => {
        const search = data ? `?username=${data}` : '';
        const res = await this.getResource(`/api/my-referrals${search}`);
        return res;
    };

    getReferralTransactions = async data => {
        const filterParams = data ? `?${data}` : '';
        const res = await this.getResource(`/api/referral-transactions${filterParams}`);
        return res;
    };

    getVersion = async () => {
        const res = await this.getResource('/public/version');
        return res;
    };

    getPaymentMethodCoins = async () => {
        const res = await this.getResource('/public/coins/payment-method/');
        return res;
    };

    getKunaFiatPairs = async () => {
        const res = await this.getResource('/api/fiat/pairs');
        return res;
    };

    getKunaFiatLimits = async type => {
        const res = await this.getResource(`/api/fiat/limits/${type}`);
        return res;
    };

    getFiatRequests = async () => {
        const res = await this.getResource('/api/fiat/requests');
        return res;
    };

    getRentActive = async () => {
        const res = await this.getResource('/api/rent/active');
        return res;
    };

    getRentCompleted = async () => {
        const res = await this.getResource('/api/rent/completed');
        return res;
    }

    getRentLimits = async coinHandle => {
        const res = await this.getResource(`/api/rent/limits/${coinHandle}`);
        return res;
    }
}
